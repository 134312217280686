.box {
	display: flex;
}
@media all and (max-width: 768px) {
	.image {
		margin: 0px !important;
		width: 40px !important;
		height: 40px !important;
	}

	.white-background {
		align-items: center;

		border-radius: 10px;
		width: 60px;
		height: 60px;
		display: flex;
		justify-content: center;
	}
}
