html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
	background: transparent;
	font-weight: normal;
	text-decoration: none;
	outline: none;
}
ol,
ul {
	list-style: none;
}
blockquote {
	quotes: none;
}
blockquote:before,
blockquote:after {
	content: "";
	content: none;
}
del {
	text-decoration: line-through;
}
header,
section,
article,
aside,
nav,
footer,
address {
	display: block;
}
body {
	/* user-select: none; */
}
@font-face {
	font-family: "Inter";
	src: url("assets/fonts/Inter.ttf") format("truetype-variations");
	font-weight: 1 999;
}
body {
	font-family: "Inter";
	width: 100%;
	min-height: 100vh;
}
input,
select {
	font-family: "Inter";
}
html,
.App {
	width: 100%;
	min-height: 100vh;
	overflow: scroll;
}
/* ::-webkit-scrollbar {
    display: none;
} */
/* ::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-track {
    background: none !important;
}

::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 20px;
} */
