.item {
	box-sizing: border-box;
	transition: 0.3s;
}
.item:nth-child(2n) {
	background: #fcfcfc;
}
.item:hover {
	border-left: 5px solid #5f2c8e;
	box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.08);
	z-index: 5;
	position: relative;
}
