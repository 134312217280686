.body {
	display: flex;
	min-height: 100vh;
	position: relative;
}
.body-container {
	width: 100%;
	min-height: 100vh;
	overflow: hidden;
	margin-left: 330px;
}
.select-input {
	border: none;
	text-align-last: center;
	padding: 15px 0;
	font-size: 18px;
	font-weight: 400;
	color: #0fa76f;
	font-family: "Inter";
	width: 160px;
	border-radius: 10px;
	margin-left: 20px;
	background: url("../../assets/images/down-arrow.svg") no-repeat right
		#f9f9f9;
	-webkit-appearance: none;
	background-position-x: 127px;
}
.select-input:focus-visible {
	outline: none;
}

.leads .number {
	background: #fff;
	display: flex;
	padding: 10px;
	align-items: center;
	border-radius: 10px;
}

.leads .number div {
	display: flex;
	flex-direction: column;
	margin-left: 10px;
}
.leads .number div img {
	cursor: pointer;
}
.leads .number div img.decrement {
	transform: rotate(180deg);
}

.item form img {
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto 0;
	left: 20px;
	width: auto;
	height: auto;
}
.item input {
	border: none;
	font-family: "Inter";
	font-size: 15px;
	font-weight: 400;
	color: #000;
	background: #f5f7fa;
	padding: 20px 15px 20px 55px;
	box-sizing: border-box;
	width: 100%;
	border-radius: 40px;
}
.item input:focus-visible {
	border: none;
	outline: none;
}
.item:hover {
	border-left: none;
	box-shadow: none;
}
.features {
	padding-top: 0px;
}

.features .icon-box {
	display: flex;
	align-items: center;
	padding: 20px;
	transition: 0.3s;
	background-color: #ffffff;
}

.features .icon-box i {
	font-size: 32px;
	padding-right: 10px;
	line-height: 1;
}

.features .icon-box h3 {
	font-weight: 700;
	margin: 0;
	padding: 0;
	line-height: 1;
	font-size: 16px;
}

.features .icon-box h3 a {
	color: #37423b;
	transition: 0.3s;
}

.features .icon-box:hover {
	border-color: #8a62e7;
}

.features .icon-box:hover h3 a {
	color: #6927d3;
}
.header div.item.form {
	width: 50%;
}
.header div.item {
	position: relative;
	cursor: pointer;
}
.header div.item img {
	width: 100%;
	height: 100%;
}
/* .header div.item:nth-child(5) {
	margin-right: 0;
	width: 6%;
} */
.header div.item form img {
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto 0;
	left: 20px;
	width: auto;
	height: auto;
}
.header div.item input {
	border: none;
	font-family: "Inter";
	font-size: 15px;
	font-weight: 400;
	color: #000;
	background: #f5f7fa;
	padding: 20px 15px 20px 55px;
	box-sizing: border-box;
	width: 100%;
	border-radius: 40px;
}
.header div.item input:focus-visible {
	border: none;
	outline: none;
}
.header div.item:hover {
	border-left: none;
	box-shadow: none;
}
@media all and (max-width: 1380px) {
	.scroll {
		overflow-x: scroll;
	}
}
@media only screen and (max-width: 1500px) {
	.body-container {
		margin-left: 60px;
	}
}
@media all and (max-width: 980px) {
	.consultant {
		font-size: 14px;
	}
	.table-td .common-table {
		font-size: 12px;
	}
}
@media all and (max-width: 360px) {
	.consultant {
		font-size: 12px;
	}
}
