.header {
    background: #fff;
    /* height: max-content;
	padding: 25px 45px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 330px);
    position: fixed;
    z-index: 100;
    height: 80px;
    box-sizing: border-box;
    padding: 10px 2.5%;
}
.header-container {
    background: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* width: 30%; */
    /* margin-right: 2.5%; */
}
.menu {
    display: none;
}
.header div.item.form {
    width: 50%;
    display: none;
}
.header div.item {
    position: relative;
    cursor: pointer;
    width: 24px;
    height: 24px;
    margin-left: 25px;
    /* background: red; */
}
.header div.item img {
    width: 100%;
    height: 100%;
}
.header div.item:nth-child(5) {
    margin-right: 0;
    width: 40px;
}
.header div.item form img {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    left: 20px;
    width: auto;
    height: auto;
}
.header div.item input {
    border: none;
    font-family: "Inter";
    font-size: 15px;
    font-weight: 400;
    color: #000;
    background: #f5f7fa;
    padding: 20px 15px 20px 55px;
    box-sizing: border-box;
    width: 100%;
    border-radius: 40px;
}
.header div.item input:focus-visible {
    border: none;
    outline: none;
}
.header div.item:hover {
    border-left: none;
    box-shadow: none;
}
@media only screen and (max-width: 1500px) {
    .header {
        width: calc(100% - 60px);
    }
    .header-container {
        width: 35%;
    }
}
@media only screen and (max-width: 1280px) {
    .header-container {
        width: 40%;
    }
}
@media only screen and (max-width: 980px) {
    .header-container {
        width: 45%;
    }
}
@media only screen and (max-width: 840px) {
    .header-container {
        width: 55%;
    }
}

/* @media only screen and (max-width: 980px) {
    .header {
        justify-content: space-between;
        padding: 25px 35px;
    }
    .menu {
        display: block;
    }
    .header-container {
        width: 100%;
    }
}
@media only screen and (max-width: 750px) {
} */
