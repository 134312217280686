.body {
	display: flex;
	min-height: 100vh;
	position: relative;
}
.body-container {
	width: 100%;
	min-height: 100vh;
	overflow: hidden;
	margin-left: 330px;
}
.select-input {
	background: #f9f9f9;
	border: none;
	text-align-last: center;
	padding: 15px 0;
	font-size: 18px;
	font-weight: 400;
	color: #0fa76f;
	font-family: "Inter";
	width: 160px;
	border-radius: 10px;
	margin-left: 20px;
	background: url("../../assets/images/down-arrow.svg") no-repeat right
		#f9f9f9;
	-webkit-appearance: none;
	background-position-x: 127px;
}
.select-input:focus-visible {
	outline: none;
}

.number {
	background: #fff;
	display: flex;
	padding: 10px;
	align-items: center;
	border-radius: 10px;
}

.number div {
	display: flex;
	flex-direction: column;
	margin-left: 10px;
}
.number div img {
	cursor: pointer;
}
.number div img.decrement {
	transform: rotate(180deg);
}
.totalLeads .chooseLeads {
	background: #f9f9f9;
	padding: 10px 20px;
	border-radius: 5px;
	cursor: pointer;
}
.totalLeads .active {
	background: #522e92;
	padding: 10px 20px;
	border-radius: 5px;
}
.totalLeads .active h3 {
	color: #fff;
}
.totalLeads .select {
	width: 100%;
	border: none;
	padding: 20px;
	background: url("../../assets/images/down-arrow.svg") no-repeat right
		#f9f9f9;
	-webkit-appearance: none;
	background-position-x: 500px;
}
.totalLeads .select-input {
	padding-right: 28px;
	margin: 0;
}

.assigned .active {
	background: #fff;
	display: flex;
	align-items: center;
	height: 50px;
	padding: 0 25px;
}
.assigned .active h3 {
	color: #0fa76f;
	font-weight: 500;
}
.assigned .menu-status {
	background: #0fa76f;
	display: flex;
	align-items: center;
	height: 50px;
	padding: 0 25px;
}
.assigned .menu-status h3 {
	color: #fdfdfd;
}
@media only screen and (max-width: 1500px) {
	.body-container {
		margin-left: 60px;
	}
}
@media only screen and (max-width: 1380px) {
	.tableScroll {
		overflow-x: scroll;
		scrollbar-width: none;
	}
	.tableScroll::-webkit-scrollbar {
		width: 0.1rem; /* Adjust as needed */
	}
	.tableScroll::-webkit-scrollbar-thumb {
		background-color: transparent; /* Hide the thumb */
	}
}
.lead-distribution {
	@media all and (max-width: 980px) {
		flex-wrap: wrap;
	}
}
@media all and (max-width: 1380px) {
	.table-container {
		overflow-x: scroll;
	}
}
@media all and (max-width: 1280px) {
	.scroll-bar {
		overflow-x: scroll;
	}
}
@media all and (max-width: 768px) {
	.top {
		flex-wrap: wrap;
	}
}
@media all and (max-width: 640px) {
	#form {
		padding: 10px 15px 11px 20px;
	}
	.converted {
		flex-wrap: wrap;
	}
	.convertedh {
		margin-bottom: 20px;
	}
	.select-input {
		width: 117px;
		background-position-x: 88px;
	}
}
@media all and (max-width: 480px) {
	.select-inputt {
		width: 100px;
		background-position-x: 88px;
	}
	.distribution {
		flex-direction: column;
		margin: 0 auto;
	}
	.add-icon {
		display: none;
	}
	.top {
		padding: 0 20px;
	}
	.pie {
		width: 60%;
		height: 60%;
	}
	.filter-icon {
		width: 10px;
	}
	.subhead {
		font-size: 20px;
	}
}
