.body {
	display: flex;
	min-height: 100vh;
	position: relative;
}
.body-container {
	width: 100%;
	min-height: 100vh;
	overflow: hidden;
	margin-left: 330px;
}

.list-campus .image-container {
	width: 90%;
	border-radius: 15px 0px 0px 15px;
}
.list-campus .campus-details {
	/* padding: 20px 30px; */
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}
.list-campus .campus-name-container {
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
	margin-bottom: 10px;
}

.list-campus .info-container .info {
	display: flex;
	margin-bottom: 30px;
	align-items: flex-start;
}
.list-campus .info-container .info .icon {
	width: 32px;
	height: 32px;
	background: rgba(82, 46, 146, 0.49);
	border-radius: 50%;
	margin-right: 10px;
	padding: 5px;
	box-sizing: border-box;
}
.search-container {
	font-family: "Inter";
	font-size: 15px;
	/* padding: 9px 10px 14px 54px; */
	box-sizing: border-box;
	background: #f5f7fa;
	border-radius: 8px;
	width: 400px;
	border: 1px solid #3ccb7f;
	height: 50px;
	display: flex;
	align-items: center;
	padding: 0 10px;
}

.input-box {
	outline: none;
	border: none;
	background-image: none;
	background-color: transparent;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	margin-left: 5px;
	width: 100%;
	height: 95%;
	font-size: 15px;
}
.no-result {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 80%;
	padding: 0 15px;
	margin-top: 10px;
}
.no-result-text {
	font-family: "Inter";
	font-size: 24px;
}
/* @media only screen and (max-width: 768px) {
    .table-head,
    .table-body {
        width: 1000px;
    }
} */
@media only screen and (max-width: 1500px) {
	.body-container {
		margin-left: 60px;
	}
}
@media only screen and (max-width: 1280px) {
	.top-title-wrapper {
		flex-wrap: wrap;
	}
	.top-title-large {
		width: 100%;
	}
	.top-title-large-right {
		margin-top: 20px;
		justify-content: end;
	}
	.university-admin-update-button {
		max-width: 150px;
	}
	.university-admin-container {
		width: 47% !important;
	}
}

@media only screen and (max-width: 1080px) {
	.top-title {
		width: 100%;
		/* margin-bottom: 20px !important; */
	}
	.top-title-right {
		width: 100%;
		justify-content: end;
		margin-top: 20px !important;
	}
	.university-admin-update-button {
		max-width: 120px;
		box-sizing: border-box;
	}
	.university-admin-container {
		width: 48% !important;
		margin: 10px 0px !important;
		padding: 20px !important;
	}
}
@media only screen and (max-width: 980px) {
	.search-container {
		/* width: 300px !important; */
	}
	.university-admin-container-wrapper {
		flex-wrap: wrap;
	}
	.university-admin-container {
		width: 55% !important;
	}
	.university-admin-update-button {
		max-width: 80px;
	}
	/* .top-title-button {
        padding-right: 15px !important;
        padding-left: 15px !important;
    } */
}
@media all and (max-width: 640px) {
	.wrap {
		flex-wrap: wrap;
	}
	.search-container {
		width: 220px !important;
		height: 40px;
	}
	.input-container {
		flex-wrap: wrap;
	}
	.head-container {
		flex-wrap: wrap;
	}
}
@media only screen and (max-width: 880px) {
	.university-admin-update-button {
		max-width: 120px;
	}
}

@media only screen and (max-width: 840px) {
	.refresh-button-large {
		display: none !important;
	}

	.university-admin-container {
		width: 65% !important;
	}
	.top-title-large-right {
		flex-direction: row;
	}
	/* .top-title-button {
        padding-right: 15px !important;
        padding-left: 15px !important;
    } */
}
@media all and (max-width: 480px) {
	.plus {
		display: none;
	}
	.search-container {
		width: 280px !important;
		margin-bottom: 20px;
	}
	.top-title-large-right {
		flex-direction: row;
		flex-wrap: wrap;
	}
	.head-container {
		flex-wrap: wrap;
		padding-left: 10px;
	}
}
/* @media all and (max-width: 640px) {
	width: 214px; 
}
@media all and (max-width: 480px) {
	width: 280px;
} */
@media all and (max-width: 360px) {
	.search-container {
		width: 240px !important;
	}
	.full-container {
		flex-wrap: wrap;
		padding: 2px;
	}
}
