.body {
	display: flex;
	min-height: 100vh;
	position: relative;
}
.body-container {
	width: 100%;
	min-height: 100vh;
	overflow: hidden;
	margin-left: 300px;
}
.select-input {
	border: none;
	text-align-last: center;
	padding: 15px 0;
	font-size: 18px;
	font-weight: 400;
	color: #522e92;
	font-family: "Inter";
	width: 160px;
	border-radius: 10px;
	margin-left: 20px;
	background: url("../../assets/images/down-arrow.svg") no-repeat right
		#f9f9f9;
	-webkit-appearance: none;
	background-position-x: 127px;
}
.select-input:focus-visible {
	outline: none;
}
.leads .number {
	background: #fff;
	display: flex;
	padding: 10px;
	align-items: center;
	border-radius: 10px;
}

.leads .number div {
	display: flex;
	flex-direction: column;
	margin-left: 10px;
}
.leads .number div img {
	cursor: pointer;
}
.leads .number div img.decrement {
	transform: rotate(180deg);
}
img.filter {
	width: 20%;
}
div.leads-list {
	flex-wrap: nowrap;
}
div.leads-headbar {
}
div.add-lead {
}
div.add-title {
}
div.add-lead-head {
}
@media only screen and (max-width: 1500px) {
	.body-container {
		margin-left: 60px;
	}
}
@media only screen and (max-width: 1280px) {
	.table {
		overflow-x: scroll;
	}
}
@media only screen and (max-width: 1080px) {
	.select-input {
		font-size: 16px !important;
	}
}
@media only screen and (max-width: 980px) {
	div.bottom-chart {
		flex-wrap: wrap;
		justify-content: center;
	}
	div.pie {
		flex-wrap: wrap;
	}
	div.pie-chart {
		height: 95% !important;
	}
}
@media only screen and (max-width: 768px) {
	img.filter {
		width: 70%;
	}
	div.add-title {
		font-size: 14px !important;
	}
}
@media only screen and (max-width: 640px) {
	div.add-lead {
		flex-wrap: wrap;
	}
	div.add-title {
		font-size: 12px !important;
	}
	div.add-lead-head {
		align-items: center;
		margin-bottom: 5px !important;
	}
	.add-input {
		padding: 15px 10px !important;
	}
}
@media only screen and (max-width: 480px) {
	div.leads-list {
		/* flex-wrap: wrap; */
	}
	div.leads-headbar {
		flex-wrap: wrap;
		justify-content: flex-end !important;
	}
	div.add-title {
		font-size: 11px !important;
	}
	.add-input {
		font-size: 13px !important;
	}
}
