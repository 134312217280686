.type {
	width: 49%;
}
.type input {
	font-size: 15px;
	/* font-style: italic; */
	font-weight: 400;
	padding: 20px 15px 20px 20px;
	box-sizing: border-box;
	width: 650px;
	border: 1px solid #dfeaf2;
	border-radius: 15px;
	margin-top: 12px;
}

.type select {
	font-size: 15px;
	/* font-style: italic; */
	font-weight: 400;
	padding: 20px 15px 20px 20px;
	box-sizing: border-box;
	width: 100%;
	border: 1px solid #dfeaf2;
	border-radius: 15px;
	margin-top: 12px;
	background-color: #fff;
	/* padding: 5px; */
}
